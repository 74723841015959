<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
            <SideMenu :indexsec="16"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  name_form,
                  lastname_form,
                  email_form,
                  password_form,
                  role_form,
                  phone_form,
                  passconfirm_form,
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR USUARIO</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                  
                    v-model="name_form"
                    required
                    pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelA color-1" for="inputA"
                    >Apellido</label
                  >
                  <b-form-input
                    v-model="lastname_form"
                    required
                    pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                    id="inputA"
                    type="text"
                  />
                </div>
              </div>

              <div class="row mx-0 row-two">
                <div class="EPF2_C1">
                  <label class="labelE color-1" for="inputE">Email</label>
                  <b-form-input   v-model="email_form" required id="inputE" type="email" />
                </div>
                <div class="">
                  <label class="labelTel color-1" for="inputTel">Teléfono</label>
                  <b-form-input
                  
                    v-model="phone_form"
                    required
                     minlength="10"
                    maxlength="10"
                    size="10"
                    pattern="\d*"
                    id="inputTel"
                    type="text"
                  />
                </div>               
                <div class="">
                  <label class="labelR color-1" for="inputR">Rol</label>
                  <b-dropdown>
                    <template #button-content>
                      <div class="row"> <span class="drop-text">{{role_form}}</span></div>
                    </template>

                    <b-dropdown-item @click="changeRole('admin')">
                      Admin
                    </b-dropdown-item>
                     <b-dropdown-item @click="changeRole('editor')">
                      Editor - Galería | Tienda
                    </b-dropdown-item>
                     <b-dropdown-item @click="changeRole('comunicacion')">
                      Comunicación - Galería | Noticias
                    </b-dropdown-item>
                     <b-dropdown-item @click="changeRole('multimedia')">
                      Multimedia - Videos
                    </b-dropdown-item>
          
                  </b-dropdown>
                </div>
              </div>

                <div class="row mx-0 row-one mtrow">
                 <div class="EPF1_C1">
                  <label class="labelP color-1" for="inputP"
                    >Contraseña</label
                  >
                  <b-form-input
                    v-model="password_form"
                    required
                    id="inputP"
                    minlength="8"
                    type="password"
                    :placeholder="'***********'"
                  />
                </div>
                 <div class="">
                  <label class="labelP color-1" for="inputP"
                    >Confirmar contraseña</label
                  >
                  <b-form-input
                   v-model="passconfirm_form"
                    required
                    id="inputP"
                    minlength="8"
                    type="password"
                    :placeholder="'***********'"
                  />
                </div>
              </div>


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El usuario se agrego correctamente</p>
                  </div>
                  <div class="">
                   

                       <div class="btns-dflx">
                      <div class="btn-void"></div>

                  <b-button class="btn-modal" @click="editarOtro()"
                      >AÑADIR OTRO</b-button
                    >
                      <b-button class="btn-modal" @click="toEdit()"
                        >EDITAR</b-button
                      >
                    </div>


                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header';

export default {
  name: "AddUser",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      name_form: "",
      lastname_form: "",
      email_form: "",
      password_form: "",
      passconfirm_form: "",
      phone_form:"",
      role_form: "admin",
      status: "",
      msg: "",
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
     added :'',
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {},
  computed:{
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toEdit: function () {
       this.$router.push("/editar/usuario/"+this.added.id).catch((err) => {});
    },
    submit: async function (name, lastname, email, password, rol,phone ,passconfirm,) {
    
     
     if(password === passconfirm){
       let user = {
        name: name,
        lastname: lastname,
        email: email,
        password: password,
        role: rol,
        phone: phone
      };
              
          this.status=''
          this.msg=''
          
          
          let result = await this.$store.dispatch("main/addItem",  {option:'user', item: user});
          
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
          console.log(result)
              this.added = result.added
              this.showModal() 
          }
         
      }else{
       
         this.status='error'
         this.msg='Las contraseñas no coinciden.'
      }
     
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    changeRole: function (value) {
      this.role_form = value;
    },
    showModal(){
       this.$refs['modal-editar'].show()
    }
  },
};
</script>


